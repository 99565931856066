/* src/index.css or src/App.css */
@font-face {
  font-family: 'SOGANGUNIVERSITYTTF';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2312-1@1.1/SOGANGUNIVERSITYTTF.woff2')
    format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap; /* Add this line */
}

body {
  margin: 0;
  font-family: 'SOGANGUNIVERSITYTTF', sans-serif, -apple-system,
    BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fcf9f6;
}

.default-bg-color {
  background-color: #fcf9f6;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.font-helvetica {
  font-family: 'Helvetica', 'Arial', sans-serif;
}

@keyframes slideInLeft {
  0% {
    transform: translateX(-50%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInRight {
  0% {
    transform: translateX(50%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.animate-slideInLeft {
  animation: slideInLeft 1s ease-out forwards;
}

.animate-slideInRight {
  animation: slideInRight 1s ease-out forwards;
}

/* Add this CSS to your stylesheet */
.mobile-menu-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Dimming effect */
  z-index: 9; /* Below the navbar */
}

.mobile-nav-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mobile-nav-link {
  padding: 12px 0; /* Increase spacing */
  width: 100%; /* Ensure full width for center alignment */
  text-align: center; /* Center text */
}

.mobile-nav-image {
  margin-bottom: 12px; /* Space below the image */
}

/* Ensure the navbar is above other content */
.mobile-navbar {
  position: relative;
  z-index: 10;
}

/* Mobile Menu container styles */
.mobile-nav-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease; /* Sliding transition */
}

/* Mobile Menu link styles */
.mobile-nav-link {
  padding: 12px 0; /* Spacing between menu items */
  width: 100%; /* Full width for center alignment */
  text-align: center; /* Center text */
}

/* Mobile Menu image styles */
.mobile-nav-image {
  margin-bottom: 12px; /* Space below image */
}

.main-navbar {
  z-index: 30; /* Higher than the mobile menu overlay */
}

/* Mobile Menu icon transition */
.icon-transition {
  transition: opacity 0.8s ease;
}

/* Mobile Menu sliding transition */
.mobile-menu-transition {
  transition: transform 0.8s ease-in-out;
}

@media (min-width: 768px) {
  .mobile-menu-container {
    display: none;
  }
}

/* Ensure the mobile menu is initially off the screen on mobile */
.mobile-menu-container {
  transform: translateX(-100%);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.font-helvetica {
  font-family: 'Helvetica', 'Arial', sans-serif;
}
